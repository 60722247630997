/*
  According to https://github.com/styled-components/styled-components/issues/2227
  font definitions should be still placed in .css file to avoid refetching fonts and
  unnecessary rerenders
*/

@font-face {
  font-family: 'Graphik';
  font-weight: 200;
  src: url('assets/fonts/Graphik-Extralight-Web.woff2') format('woff2');
}

@font-face {
  font-family: 'Graphik';
  font-weight: 400;
  src: url('assets/fonts/Graphik-Regular-Web.woff2') format('woff2');
}

@font-face {
  font-family: 'Graphik';
  font-weight: 500;
  src: url('assets/fonts/Graphik-Medium-Web.woff2') format('woff2');
}

@font-face {
  font-family: 'Graphik';
  font-weight: 600;
  src: url('assets/fonts/Graphik-Semibold-Web.woff2') format('woff2');
}

.frf-water {
  visibility: hidden;
}

.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  right: -5px;
  bottom: 0;
  z-index: 1;
  width: 10px;
  height: 100%;
  cursor: col-resize;
}
